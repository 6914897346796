$prefixClass: 'rangedInput';

$disabledColor: #ccc;
$border-radius-base: 6px;
$tooltip-color: #fff;
$tooltip-bg: tint(#666, 4%);
$tooltip-arrow-width: 4px;
$tooltip-distance: $tooltip-arrow-width + 4;
$tooltip-arrow-color: $tooltip-bg;
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);

@mixin borderBox() {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(
      0,
      0,
      0,
      0
    ); //  remove tap highlight color for mobile safari
  }
}

.rangedInput {
  position: relative;

  margin: 0 auto;

  width: 95%;
  height: 50px;

  border-radius: 4px;

  touch-action: none;
  // @include borderBox();

  &-rail {
    position: absolute;
    top: 50%;

    width: 100%;
    height: 3px;

    border-radius: 4px;

    background-color: $color-secondary-100;

    transform: translateY(-50%);
  }

  &-track {
    position: absolute;
    left: 0;
    top: 50%;

    height: 3px;
    // border-radius: $border-radius-base;
    border-radius: 4px 0px 0px 4px;

    background-color: var(--color-primary);

    transform: translateY(-50%);
  }

  &-handle {
    box-sizing: content-box;

    position: absolute;
    top: 50%;

    margin-top: -11px;

    width: 16px;
    height: 16px;

    border: solid 3px #ffff;
    border-radius: 50%;

    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    touch-action: pan-x;

    background-color: $color-primary;

    transform: translateY(-50%);

    &::before {
      content: '';

      position: absolute;
      top: -3px;
      left: -3px;

      width: calc(100%);
      height: calc(100%);

      border: solid 3px #ffff;
      border-radius: 50%;
    }

    // &-dragging&-dragging&-dragging {
    &dragging {
      box-shadow: 0 0 0 5px tint($color-primary, 50%);
    }

    &:focus {
      outline: none;
    }

    &-click-focused:focus {
      box-shadow: unset;
    }

    &:active {
      cursor: -webkit-grabbing;
      cursor: grabbing;

      box-shadow: 0 0 5px tint($color-primary, 20%);
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;

    width: 100%;

    font-size: 12px;
  }

  &-mark-text {
    display: inline-block;

    position: absolute;

    cursor: pointer;

    text-align: center;
    vertical-align: middle;

    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;

    width: 100%;
    height: 4px;

    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2px;

    margin-left: -4px;

    width: 8px;
    height: 8px;

    border: 2px solid #e9e9e9;
    border-radius: 50%;

    cursor: pointer;

    vertical-align: middle;

    background-color: #fff;
    &-active {
      border-color: tint($color-primary, 50%);
    }
    &-reverse {
      margin-right: -4px;
    }
  }

  &-disabled {
    background-color: #e9e9e9;

    .#{$prefixClass}-track {
      background-color: $disabledColor;
    }

    .#{$prefixClass}-handle,
    .#{$prefixClass}-dot {
      border-color: $disabledColor;

      cursor: not-allowed;

      background-color: #fff;
      box-shadow: none;
    }

    .#{$prefixClass}-mark-text,
    .#{$prefixClass}-dot {
      cursor: not-allowed !important;
    }
  }
}

.#{$prefixClass}-vertical {
  padding: 0 5px;

  width: 14px;
  height: 100%;

  .#{$prefixClass} {
    &-rail {
      height: 100%;
      width: 4px;
    }

    &-track {
      left: 5px;
      bottom: 0;

      width: 4px;
    }

    &-handle {
      margin-left: -5px;

      touch-action: pan-y;
    }

    &-mark {
      top: 0;
      left: 18px;

      height: 100%;
    }

    &-step {
      height: 100%;
      width: 4px;
    }

    &-dot {
      left: 2px;

      margin-bottom: -4px;
      &:first-child {
        margin-bottom: -4px;
      }
      &:last-child {
        margin-bottom: -4px;
      }
    }
  }
}

@mixin motion-common() {
  display: block !important;

  animation-duration: 0.3s;
  animation-fill-mode: both;
}

@mixin make-motion($className, $keyframeName) {
  .#{$className}-enter,
  .#{$className}-appear {
    @include motion-common();
    animation-play-state: paused;
  }
  .#{$className}-leave {
    @include motion-common();
    animation-play-state: paused;
  }
  .#{$className}-enter.#{$className}-enter-active,
  .#{$className}-appear.#{$className}-appear-active {
    animation-name: '#{keyframeName}In';
    animation-play-state: running;
  }
  .#{$className}-leave.#{$className}-leave-active {
    animation-name: '#{keyframeName}Out';
    animation-play-state: running;
  }
}
@mixin zoom-motion($className, $keyframeName) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter,
  .#{$className}-appear {
    transform: scale(0, 0); // need this by yiminghe

    animation-timing-function: $ease-out-quint;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-quint;
  }
}
@include zoom-motion(rc-slider-tooltip-zoom-down, rcSliderTooltipZoomDown);

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;

    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
}

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;

    transform-origin: 50% 100%;
    transform: scale(0, 0);
  }
}

.#{$prefixClass}-tooltip {
  @include borderBox();
  position: absolute;
  left: -9999px;
  top: -9999px;

  visibility: visible;

  &-hidden {
    display: none;
  }

  &-placement-top {
    padding: $tooltip-arrow-width 0 $tooltip-distance 0;
  }

  &-inner {
    padding: 6px 2px;

    min-width: 24px;
    height: 24px;

    border-radius: $border-radius-base;

    font-size: 12px;
    line-height: 1;
    text-align: center;
    text-decoration: none;

    background-color: $tooltip-bg;
    color: $tooltip-color;
    box-shadow: 0 0 4px #d9d9d9;
  }

  &-arrow {
    position: absolute;

    width: 0;
    height: 0;

    border-color: transparent;
    border-style: solid;
  }

  &-placement-top &-arrow {
    bottom: $tooltip-distance - $tooltip-arrow-width;
    left: 50%;

    margin-left: -$tooltip-arrow-width;

    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $tooltip-arrow-color;
  }
}
