@import '@hxm/styles/config';

.loading {
  display: flex;

  align-items: center;
  justify-content: center;

  user-select: none;

  background: transparent;

  transition: opacity 200ms ease-in;
}

.loadingIndicator {
  @include loading-indicator(24px, 2px, $color-primary-100-rgb);
}
