// PROJECT-SPECIFIC global css should go here
@import 'reset';
@import 'config';

// global form styles imported separately
@import 'forms';
@import 'sliderInput';

:root {
  // ANIMATION / TRANSITION
  --default-ease: #{$default-ease};
  --transition-duration: #{$transition-duration};

  // COLORS
  --color-background: #{$color-background};
  --color-border: #{$color-border};
  --color-font: #{$color-font};
  --color-link: #{$color-link};

  @if variable-exists(color-secondary) {
    --color-secondary: #{$color-secondary};
  }

  // LAYOUT
  --gutter: #{$gutter};

  @if variable-exists(header-height-mobile) {
    --header-height: #{$header-height-mobile};
  }

  @media (min-width: $min-desktop) {
    @if variable-exists(header-height) {
      --header-height: #{$header-height};
    }
  }

  @media (prefers-reduced-motion: reduce) {
    --transition-duration: 1ms;
  }
}

@mixin html-overflow-hidden {
  overflow-y: hidden;

  body {
    padding-right: var(--browser-scrollbar-width);

    touch-action: none;
  }
}

html {
  @include responsive-font($font-size-min, $font-size);

  font-family: $font-family;
  line-height: $line-height;

  background: var(--color-background, $color-background);

  color: var(--color-font, $color-font);

  &.scroll-disabled {
    // if nav is open
    @include html-overflow-hidden;
  }

  &.nav-open {
    // if nav is open
    @media (max-width: $max-desktop) {
      @include html-overflow-hidden;
    }
  }
}

hr {
  border-color: var(--color-border, $color-border);
}

a {
  text-decoration: none;

  color: var(--color-link, inherit);

  &:where(:hover, :focus-visible) {
    text-decoration: underline;
  }
}

.tippy-box[data-theme~='tippy-override'] {
  border: 1px solid $color-secondary-100;

  background-color: $color-primary-100;
  color: $color-secondary-600;
}

.tippy-box[data-theme~='tippy-override'] > .tippy-arrow::before {
  color: $color-primary-100;
}
.tippy-box[data-theme~='tippy-override'][data-placement^='top'] > .tippy-arrow::before {
  border-top-width: 7px;
  border-top-color: $color-secondary-100;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tippy-box[data-theme~='tippy-override'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-width: 7px;
  border-bottom-color: $color-secondary-100;
  border-right-color: transparent;
  border-left-color: transparent;
}
.tippy-box[data-theme~='tippy-override'][data-placement^='left'] > .tippy-arrow::before {
  border-left-width: 7px;
  border-left-color: $color-secondary-100;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.tippy-box[data-theme~='tippy-override'][data-placement^='right'] > .tippy-arrow::before {
  border-right-width: 7px;
  border-right-color: $color-secondary-100;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */
  display: inline-flex;

  position: relative;

  margin-bottom: 2px;

  width: 100%;
  height: 32px;
  overflow: hidden;

  border-radius: 0.25rem;

  user-select: none;

  line-height: 1;

  background-color: var(--base-color);

  &::after {
    content: ' ';

    display: var(--pseudo-element-display);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100%;

    background-repeat: no-repeat;
    background-image: var(
      --custom-highlight-background,
      linear-gradient(
        90deg,
        var(--base-color) 0%,
        var(--highlight-color) 50%,
        var(--base-color) 100%
      )
    );

    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @media (prefers-reduced-motion) {
    & {
      --pseudo-element-display: none; /* Disable animation */
    }
  }
}
