@import '@hxm/styles/config';

.layout {
  @media (min-width: $min-desktop) {
    border-top: 8px solid var(--color-primary);
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    position: relative;

    padding: 0 $gutter;

    min-height: 100vh;
    min-width: 320px;

    @media (max-width: $max-desktop) {
      // header fixed in mobile, create space for it
      padding-top: $header-height-mobile;
      padding-top: var(--header-height);
    }

    @media (min-width: $min-desktop) {
      margin: 0 auto;
      padding: 0 $page-gutter-desktop 60px;
      padding-left: $sidebar-width-desktop + (2 * $page-gutter-desktop);

      min-height: calc(100vh - 8px);
      max-width: $page-limit;
    }

    &__content {
      flex-grow: 1;

      @media (max-width: $max-mobile) {
        // trans-x for mobile open close
        .navOpen & {
          transform: translate3d(calc(var(--gutter) * -2), 0, 0);

          transition: transform var(--transition-duration) var(--default-ease);
        }
      }
    }
  }
}
