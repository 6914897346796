@import '@hxm/styles/config';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $zindex-header;

  min-width: 320px;

  height: var(--header-height, $header-height);

  background-color: $color-secondary-50;

  @media (max-width: $max-desktop) {
    transition: all $transition-duration $default-ease;
    &.hidden {
      transform: translate3d(0, calc(var(--header-height) * -1), 0);
    }
  }

  @media (min-width: $min-desktop) {
    position: relative;

    &:not(.welcome) {
      margin-left: -248px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 $gutter;

    height: var(--header-height, $header-height);
    @media (min-width: $min-desktop) {
      padding: 0 $gutter 0 0;
    }
  }

  &__skip {
    position: absolute;
    top: 0;

    transform: translateY(-150%);

    &:focus {
      transform: none;
    }
  }

  &__logo {
    color: #000;
  }

  &__content {
    margin-left: auto;
  }

  &__controls {
    margin-left: var(--gutter);

    @media (min-width: $min-desktop) {
      display: none;
    }
  }
}
