@import '@hxm/styles/config';

.button {
  @include button;

  &.secondary {
    @include secondary-button;
  }

  &.tertiary {
    color: $color-primary-ui;

    background-color: $color-secondary-75;

    &:hover {
      filter: brightness(90%);
    }
  }
}
